import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '../components/Buttons/Button';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Inner from '../components/Styles/Inner';
import Form from '../components/Styles/Form';
import PageHeader from '../components/PageHeader';
import Section from '../components/Section';

class RequestQuotePage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Request a Quote" />
        <PageHeaderStyled
          heading="Request a Free Quote"
          subHeading="Let us know what you're looking for and we'll be in touch to discuss your project."
        />
        {/* <Section>
          <InnerStyled>
            <div className="left">
              <Form id="request-quote-form" method="POST" data-netlify="true">
                <input
                  type="hidden"
                  name="form-name"
                  value="request-quote-form"
                />
                <fieldset>
                  <div className="input-group">
                    <label htmlFor="name">
                      Name
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      type="text"
                      aria-label="text input"
                      id="name"
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="email">
                      Email
                      <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      type="text"
                      aria-label="text input"
                      id="email"
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input type="tel" aria-label="phone number" id="phone" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="company">Company</label>
                    <input type="text" aria-label="text input" id="company" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="website">Website</label>
                    <input type="text" aria-label="text input" id="website" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="message">Describe Your Project</label>
                    <textarea id="message" />
                  </div>
                  <div className="input-group">
                    <label htmlFor="budget">Budget</label>
                    <select id="budget">
                      <option value="Not Specified">Please choose...</option>
                      <option value="$5,000-$10,000">$5,000-$10,000</option>
                      <option value="$10,000-$25,000">$10,000-$25,000</option>
                      <option value="$25,000-$50,000">$25,000-$50,000</option>
                      <option value="$50,000+">$50,000+</option>
                    </select>
                  </div>
                  <Button>Send</Button>
                </fieldset>
              </Form>
            </div>
          </InnerStyled>
        </Section> */}
      </Layout>
    );
  }
}

const PageHeaderStyled = styled(PageHeader)`
  min-height: 35vh;
`;

const InnerStyled = styled(Inner)`
  max-width: 960px;
`;

export default RequestQuotePage;
